import { default as Map } from "@/map_v2/App";
import { default as InKindHousePanel } from "@/newPurchase/components/inKindHousePanel";
import { default as SubscriptionCalculator } from "@/newPurchase/components/SubscriptionCalculator";
import { default as Checkout } from "@/newCheckout/checkout";
import { default as OfferClaimApp } from "@/offer/App";
import { default as MobileLink } from "../ui/organisms/MobileLink/index";
import { default as RedeemCheckout } from "@/checkout/checkout";
import { default as BulkGiftingSavingsCalculator } from "@/newPurchase/components/savingsCalculator";
import { default as BulkGiftingApp } from "@/newCheckout/massGifting/App";
import { default as BulkGiftingBrandCarousel } from "@/newCheckout/massGifting/BrandCarousel";
import { default as BulkGiftingAbsolutePanel } from "@/newPurchase/components/absolutePanel";
import { default as CampaignFeedbackApp } from "@/feedback/App";
import { default as AmexApp } from "@/amex/App";
import { default as PrivacyChoices } from "@/privacy_choices/App";
import { default as DynamicSlider } from "@/dynamic-slider/App";
import { default as NewLoginCreateAccount } from "@/new-login-create-account/NewLoginCreateAccount";
import { default as MenuVisibilityForm } from "@/menu-visibility-form/App";
import { default as PartnerPortal } from "@/partner-portal/App";
import { default as AmbassadorUserProfile } from "@/ambassador/user-profile/index";
import { default as UserFlow } from "@/ambassador/2fa-user-flow/index";
import { default as AmbassadorWebDiscovery } from "@/ambassador/ambassador-web-discovery/index";
import { default as WebRedemption } from "@/web-redemption/App";

import ReactOnRails from "react-on-rails";

ReactOnRails.register({
  AmbassadorUserProfile,
  Map,
  InKindHousePanel,
  MobileLink,
  SubscriptionCalculator,
  Checkout,
  OfferClaimApp,
  RedeemCheckout,
  BulkGiftingSavingsCalculator,
  BulkGiftingApp,
  BulkGiftingBrandCarousel,
  BulkGiftingAbsolutePanel,
  CampaignFeedbackApp,
  AmexApp,
  PrivacyChoices,
  DynamicSlider,
  NewLoginCreateAccount,
  MenuVisibilityForm,
  PartnerPortal,
  UserFlow,
  WebRedemption,
  AmbassadorWebDiscovery,
});
