import React, { useEffect } from "react";
import numeral from "numeral";
import { OfferState } from "./App";

const WelcomeBack = ({
  value,
  displayState,
  errorState,
  ikname,
  expirationDays,
  inkindStateDomain,
  handleAmplitude,
}) => {
  const android = navigator.userAgent.toLowerCase().indexOf("android") > -1;

  useEffect(() => {
    if (displayState === OfferState.success && !errorState) {
      handleAmplitude("claim_modal_offer", {});
    }
  }, []);
  const handleAppLaunchClick = async () => {
    await handleAmplitude("click_open_inKind_app", {});
    if (!android) {
      setTimeout(function () {
        window.location = inkindStateDomain;
      }, 500);
    } else {
      let appLinkTimeout = setTimeout(function () {
        window.location = inkindStateDomain;
      }, 500);
      let clearAppLinkTimeout = function () {
        if (appLinkTimeout) {
          clearTimeout(appLinkTimeout);
          appLinkTimeout = null;
        }
      };
      window.addEventListener("blur", function () {
        if (clearAppLinkTimeout) {
          clearAppLinkTimeout();
        }
      });
    }
  };

  const headerText = errorState
    ? "Something went wrong..."
    : displayState === OfferState.success
      ? "Welcome back!"
      : displayState === OfferState.prevClaimed
        ? "You’ve already claimed this offer!"
        : displayState === OfferState.claimOwnReferral
          ? "Something went wrong..."
          : "You’ve already used this offer!";

  const bottomText = errorState
    ? "We don’t recognize your unique code, it may be because you have already claimed this offer. Contact support for help or download our app"
    : displayState === OfferState.success
      ? `Your gift has been added to your account. Open the ${ikname} app to
      manage your balance and use your credit.`
      : displayState === OfferState.claimOwnReferral
        ? `Sorry, you cannot claim your own offer.`
        : `It looks like you’ve already claimed this offer.
        But you can still get a bonus by adding to your ${ikname} balance below.`;

  return (
    <div className="welcome-back">
      <div className="welcome-back-top">
        <div className="header">{headerText}</div>
        {displayState === OfferState.success && !errorState && (
          <div className="subheader">
            You’ve unlocked{" "}
            <span className="highlight">
              {numeral(value).format("$0[.]00")}
            </span>
          </div>
        )}
      </div>
      <div className="divider-new"></div>
      <div className="welcome-back-bottom">
        <p>{bottomText}</p>
        <button
          className={errorState ? "somthing-went-wrong" : "button-link"}
          onClick={handleAppLaunchClick}
          type="button"
        >
          Open the {ikname} app
        </button>
      </div>
      <div className="welcome-back-footer">
        <div>
          You must have the {ikname} app to use at participating restaurants.
        </div>
        <div className="org-tos">
          {`Offers may not be combined with any other offer.
            ${
              expirationDays
                ? `Offer is valid for ${expirationDays} day${
                    expirationDays === 1 ? "" : "s"
                  } after claiming.`
                : ""
            }`}
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;
