import * as React from "react";
import ReactApexChart from "react-apexcharts";

const EarningsChart = ({ earnings }) => {
  const [series, _setSeries] = React.useState(earnings.stats);
  const allEarnings = earnings.stats[0].data.concat(earnings.stats[1].data);
  const maxChartValue = Math.max(...allEarnings) + 100;
  const stepSize = Math.ceil(maxChartValue / 10);

  return (
    <div className="earnings-chart">
      <ReactApexChart
        options={{
          colors: ["#C4DDF5", "#1276D6"],
          chart: {
            background: "#fff",
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            categories: earnings.currentEarningPeriod,
          },
          yaxis: {
            stepSize: stepSize,
            logarithmic: true,
            logBase: 10,
            min: 0,
            max: maxChartValue,
          },
          title: {
            text: "Earnings",
            margin: 40,
            floating: true,
          },
        }}
        series={series}
        type="line"
        height="326px"
        width="100%"
      />
    </div>
  );
};

export default EarningsChart;
