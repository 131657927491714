import React from "react";
import useDeviceType from "../../hooks/useDeviceType";

const NewUserNoLongerAvailable = ({
  ikname,
  enterprise,
  inkindStateAppStoreUrl,
  inkindStatePlayStoreUrl,
  handleAmplitude,
}) => {
  const { isApple, isAndroid } = useDeviceType();

  const QRCode = require(
    `../../../assets/images/enterprise/${enterprise}/icons/app-qr-code.svg`,
  );
  const twoIphone = require(
    `../../../assets/images/enterprise/${enterprise}/icons/two-iphone.png`,
  );
  const handleAmplitudeOpenApp = () => {
    handleAmplitude("click_open_inKind_app", {});
  };
  return (
    <div className="new-user-no-longer-available">
      <div className="new-user-no-longer-available-top">
        <p className="superheader">offer is no longer available</p>
        <div className="header">But don’t worry!</div>
        <div className="subheader">
          Get up to 15% back by just downloading {ikname} and paying with the
          app
        </div>
      </div>
      <div className="divider-new"></div>
      <div className="new-user-no-longer-available-bottom">
        <div className="image-contianer">
          <img
            src={twoIphone}
            className="two-iphone-short"
            alt="Two iphones side by side"
          />
        </div>
        <div className="unlocked-offer-claim-container code-main">
          <div className="small-header offer-header">
            <div className="flex-header">Download the app</div>
            <p className="claim-texted-info">
              Scan the QR code if click the App Store or Google Play buttons to
              get started
            </p>
          </div>
          <div className="download-links-desktop">
            <img className="qrcode" src={QRCode} />
            <div className="download-app-btns">
              <a
                className="download-button apple-button"
                href={inkindStateAppStoreUrl}
                target="_blank"
                style={{ display: isAndroid ? "none" : "" }}
                onClick={handleAmplitudeOpenApp}
              ></a>
              <a
                className="download-button google-button"
                href={inkindStatePlayStoreUrl}
                target="_blank"
                style={{ display: isApple ? "none" : "" }}
                onClick={handleAmplitudeOpenApp}
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUserNoLongerAvailable;
