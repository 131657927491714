import React, { useState, useRef } from "react";
import { ErrorMessage, Form, Formik, FormikErrors, FormikProps } from "formik";
import { object, string } from "yup";
import validator from "validator";
import { FormikRadioButton } from "../../ui/atoms/formik-radio-button";
import FormikTextInput from "../../ui/atoms/formik-text-input";
import { TipOptions } from "./TipOptions";
import { PaymentMethod } from "./PaymentMethod";
import {
  FormValues,
  PaymentCard,
  PaymentPayload,
  PaymentResponse,
  ProjectData,
  TicketData,
} from "./type";
import { useStripePaymentRequest } from "./useStripePaymentRequest";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import TipConfirmationModal from "./TipConfirmationModal";
import { calcTotalQuantity } from "./Receipt";

const setAllFieldsTouched = (
  formikRef: React.RefObject<FormikProps<FormValues>> | undefined,
) => {
  if (formikRef?.current) {
    const touchedFields = Object.keys(formikRef.current?.initialValues).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    formikRef.current.setTouched(touchedFields);
  }
};

export function parseTipAmount(tipAmount: string): number {
  if (!tipAmount || tipAmount.trim() === "") {
    return 0;
  }
  const cleanedAmount = tipAmount.replace("$", "");
  const parsedAmount = parseFloat(cleanedAmount);

  if (isNaN(parsedAmount)) {
    return 0;
  }
  return parseFloat(parsedAmount.toFixed(2));
}
function splitFullName(fullName: string) {
  const nameParts = fullName.trim().split(" ");
  if (nameParts.length < 2) {
    return {
      firstName: fullName,
      lastName: "",
    };
  }
  return {
    firstName: nameParts.slice(0, nameParts.length - 1).join(" "),
    lastName: nameParts[nameParts.length - 1],
  };
}

export const hasHighTip = (
  values: FormValues | undefined,
  tipCalculationBase: number,
) => {
  const totalTip = parseTipAmount(values?.tip_amount || "");
  const tipPercentage = totalTip / tipCalculationBase;
  const highTipOver100 = tipCalculationBase >= 100 && tipPercentage > 0.35;
  const highTipLess100 = tipCalculationBase < 100 && tipPercentage > 1;
  return highTipOver100 || highTipLess100;
};

const validateName = (value?: string) => {
  if (value?.startsWith(" ")) {
    return "No spaces allowed";
  }
  if (/\d/.test(value ?? "")) {
    return "No numbers allowed";
  }
  if (/\W/.test(value ?? "")) {
    return "No symbols allowed";
  }
  return "";
};

export type CheckoutProps = {
  posSlug?: string;
  locationId: string;
  ticketData: TicketData;
  projectData: ProjectData;
  ticketCode: string;
  locationData: {
    tippingEnabled: boolean;
    tipOptions: (number | undefined)[];
    locationsCount: number;
  };
  showViewOrder: () => void;
  onSuccess: () => void;
  setCardInfo: React.Dispatch<React.SetStateAction<PaymentCard>>;
  setPaymentResponse: React.Dispatch<React.SetStateAction<PaymentResponse>>;
};
type BannerProps = {
  message: string;
  onClose: () => void;
};
const PayingBillModal: React.FC = () => {
  return (
    <div className="paying-bill-modal">
      <div className="paying-bill-modal__overlay">
        <div className="paying-bill-modal__content">
          <div className="paying-bill-modal__image" />
          <div className="paying-bill-modal__text">Paying your bill</div>
        </div>
      </div>
    </div>
  );
};

const Banner: React.FC<BannerProps> = ({ message, onClose }) => {
  return (
    <div className="banner">
      <div className="banner__message">{message}</div>
      <button onClick={onClose} className="banner__dismiss-button">
        Dismiss
      </button>
    </div>
  );
};

const createValidationSchema = (tippingEnabled: boolean) =>
  object().shape({
    phone: string().when("opt_in", ([opt_in], schema) => {
      return opt_in === "opt-in"
        ? schema
            .required("Phone number is required")
            .matches(
              /^(\+1\s?)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              "Invalid phone number",
            )
        : schema.notRequired();
    }),
    tip_amount: string().when(
      ["payment_method"],
      ([payment_method], schema) => {
        return payment_method !== "inkind" && tippingEnabled
          ? schema
              .required("Tip amount is required")
              .matches(
                /^\$(?:0\.00|0\.[5-9]\d|[1-9]\d*\.\d{2})$/,
                "A tip should be at least $0.51 and in the format $0.00",
              )
          : schema.notRequired();
      },
    ),
    payment_method: string().required("Payment method is required"),
    opt_in: string().required("Please choose one option"),
    first_name: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema.required("Missing first name").test(
            "valid-name",
            (value) => validateName(value.value),
            (value) => !validateName(value),
          )
        : schema.notRequired();
    }),
    last_name: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema.required("Missing last name").test(
            "valid-name",
            (value) => validateName(value.value),
            (value) => !validateName(value),
          )
        : schema.notRequired();
    }),
    email: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema
            .email("Your email is invalid.")
            .required("Missing email")
            .test("valid-email", "Your email is invalid.", (value) =>
              validator.isEmail(value),
            )
        : schema.notRequired();
    }),
    zip: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema
            .required("Missing zip code")
            .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code format")
        : schema.notRequired();
    }),
    card_expiry: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema
            .required("Missing card expiry")
            .test(
              "is-not-error",
              "Your card's expiration date is incomplete.",
              (value) => value !== "error",
            )
        : schema.notRequired();
    }),
    card_number: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema
            .required("Missing card number")
            .test(
              "is-not-error",
              "Your card number is invalid.",
              (value) => value !== "error",
            )
        : schema.notRequired();
    }),
    cvv: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card"
        ? schema
            .required("Missing CVV")
            .test(
              "is-not-error",
              "Your card's security code is incomplete.",
              (value) => value !== "error",
            )
        : schema.notRequired();
    }),
  });

const initialValues: FormValues = {
  phone: "",
  tip_amount: "",
  opt_in: "",
  payment_method: "",
  first_name: "",
  last_name: "",
  email: "",
  card_expiry: "",
  card_number: "",
  cvv: "",
  zip: "",
  accept_high_tip: false,
};

const Checkout: React.FC<CheckoutProps> = ({
  posSlug,
  locationId,
  ticketData,
  projectData,
  locationData,
  ticketCode,
  showViewOrder,
  onSuccess,
  setCardInfo,
  setPaymentResponse,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, setErrorMsg] = useState("");
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [showTipConfirmation, setShowTipConfirmation] = useState(false);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const {
    bill_house_account_amount: billHouseAccountAmount = 0,
    bill_total: billTotal = 0,
    bill_amount_due: billAmountDue = 0,
    tip_calculation_base: tipCalculationBase = 0,
    line_items: lineItems = [],
    service_charges: serviceCharges = [],
  } = ticketData;
  const rewardsAmount = (billHouseAccountAmount * 0.15).toFixed(2);
  const autoGrat =
    serviceCharges?.find((sc) => sc.type === "gratuity")?.amount || 0;
  const { paymentRequest, availableMethod, updatePaymentRequestOptions } =
    useStripePaymentRequest({
      billAmount: billAmountDue,
      formikRef,
      handleToken: handleGoogleApplePay,
    });
  const totalQuantity = calcTotalQuantity(lineItems);

  const handleCardPayment = async (name: string, zip: string) => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) return;

    const { error, token } = await stripe.createToken(cardNumberElement, {
      name,
      address_zip: zip,
    });
    if (error) {
      throw error;
    }
    return token;
  };

  async function handleGoogleApplePay(event: any) {
    setAllFieldsTouched(formikRef);
    const errors = await formikRef?.current?.validateForm();
    const isValid = Object.keys(errors ?? {}).length === 0;
    const isHighTip =
      hasHighTip(formikRef?.current?.values, tipCalculationBase) &&
      !formikRef?.current?.values.accept_high_tip;

    if (!isValid) {
      if (errors?.opt_in || errors?.phone) {
        window.scrollTo({
          top: 200,
          behavior: "smooth",
        });
      }
      event.complete("fail");
      setErrorMsg("One or more fields have issues.");
      return;
    }
    if (isHighTip) {
      setShowTipConfirmation(true);
      event.complete("fail");
      return;
    }
    event.complete("success");
    setErrorMsg("");
    handlePayment(formikRef?.current?.values || ({} as FormValues), event);
  }

  async function handlePayment(values: FormValues, event: any = {}) {
    try {
      setSubmittingPayment(true);
      setErrorMsg("");
      const payload: PaymentPayload = {
        project_id: projectData?.project_id,
        location_id: projectData?.location_id,
        ticket_number: ticketData?.ticket_code || "",
        customer: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
        },
        payment_method: {
          stripe_token: "",
        },
        transaction: {
          amount: billAmountDue,
          notes: "",
          partial_payment: false,
        },
        tip: {
          amount: parseTipAmount(values.tip_amount || "") * 100,
        },
        opt_in: {
          inkind: values.opt_in === "opt-in",
        },
        utm: {},
      };
      if (values.payment_method === "card") {
        const fullName = `${values.first_name} ${values.last_name}`;
        const token = await handleCardPayment(fullName, values.zip);
        payload.payment_method.stripe_token = token?.id ?? "";
        payload.customer = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          zip: values.zip,
        };
        setCardInfo((token?.card || {}) as PaymentCard);
      } else {
        const { firstName, lastName } = splitFullName(event.payerName);
        payload.payment_method.stripe_token = event?.token?.id ?? "";
        payload.customer = {
          first_name: firstName,
          last_name: lastName,
          email: event.payerEmail,
          phone: values.phone || event.payerPhone,
          zip: event?.token?.card?.address_zip || "",
        };
        setCardInfo(event?.token?.card || {});
      }
      const { data } = await axios.post("/api/v5/pay", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!data?.pos_success) throw new Error("Something went wrong.");
      setPaymentResponse(data);
      onSuccess();
    } catch (error) {
      const errorMessage = axios.isAxiosError(error)
        ? error?.response?.data?.errors?.[0].message || error.message
        : error.message;
      setErrorMsg(errorMessage);
    } finally {
      setSubmittingPayment(false);
    }
  }

  const handleSubmit = (values: FormValues) => {
    if (values.accept_high_tip || !hasHighTip(values, tipCalculationBase)) {
      handlePayment(values);
      return;
    }
    setShowTipConfirmation(true);
  };

  const handleCountinuePayment = (
    values: FormValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<FormValues>>,
  ) => {
    setFieldValue("accept_high_tip", true);
    setErrorMsg("");
    if (values.payment_method === "card") return handlePayment(values);
    else if (paymentRequest) {
      return paymentRequest.show();
    }
  };

  return (
    <>
      <div className="checkout">
        <div className="header">
          <div className="checkout-text">Checkout</div>
          <button className="view-order-button" onClick={showViewOrder}>
            <div className="receipt-icon" />
            <span>View order</span>
          </button>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={createValidationSchema(
            locationData?.tippingEnabled,
          )}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="form">
              <div className="section">
                <div className="title">
                  Join inKind Rewards to get{" "}
                  <span className="highlight">${rewardsAmount}</span> Back on
                  your meal today
                </div>
                <div className="sub-title">
                  Spend your ${rewardsAmount} Reward at{" "}
                  {locationData?.locationsCount}+ restaurants
                </div>
                <div className="opt-in-container">
                  <div className="opt-in">
                    <div className="highlight">Yes, Join inKind Rewards</div>
                    <FormikRadioButton
                      name="opt_in"
                      value="opt-in"
                      className="opt-in-radio"
                      label="I agree to receive text messages from inKind, including verification & marketing messages at the phone number provided. Reply STOP to cancel. Message & data rates may apply. "
                    />
                    {values.opt_in === "opt-in" && (
                      <FormikTextInput label="Phone Number" name="phone" />
                    )}
                  </div>
                  <div className="opt-out">
                    <FormikRadioButton
                      name="opt_in"
                      value="opt-out"
                      label={`No thanks, I don’t want $${rewardsAmount} Back`}
                    />
                  </div>
                  <ErrorMessage
                    name="opt_in"
                    component="div"
                    className="opt-in__error"
                  />
                </div>
              </div>
              <div className="section">
                <div className="bill-total">
                  <span>
                    Bill Total ({totalQuantity} Item
                    {totalQuantity > 1 ? "s" : ""})
                  </span>
                  <span>${billTotal.toFixed(2)}</span>
                </div>
                {locationData?.tippingEnabled && (
                  <TipOptions
                    tipCalculationBase={tipCalculationBase}
                    tipOptions={locationData?.tipOptions || []}
                    updatePaymentRequestOptions={updatePaymentRequestOptions}
                    paymentRequest={paymentRequest}
                    hasAutomaticGratuity={!!autoGrat}
                  />
                )}
              </div>
              <PaymentMethod
                paymentRequest={paymentRequest}
                availableMethod={availableMethod}
                billAmount={billAmountDue}
                locationId={projectData?.location_id + ""}
                ticketCode={ticketCode}
                posSlug={posSlug}
              />
              <TipConfirmationModal
                isOpen={showTipConfirmation}
                tipAmount={parseTipAmount(values?.tip_amount || "")}
                autoGrat={autoGrat}
                tipCalculationBase={tipCalculationBase}
                closeModal={() => setShowTipConfirmation(false)}
                continuePayment={() =>
                  handleCountinuePayment(values, setFieldValue)
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      {submittingPayment && <PayingBillModal />}
      {errorMsg && (
        <Banner message={errorMsg} onClose={() => setErrorMsg("")} />
      )}
    </>
  );
};

export default Checkout;
