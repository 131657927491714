import React from "react";
import CodeBox from "./CodeBox";
import VerificationBox from "../../ui/organisms/VerificationBox";
import ClaimBox from "./ClaimBox";
import AltVerification from "./AltVerification";
import WelcomeBonus from "./WelcomeBonus";
import OfferCode from "./OfferCode";
import SignIn from "./SignIn";
import ikLogo from "../../../assets/images/checkout/inkind_white.png";
import fetch from "isomorphic-fetch";
import numeral from "numeral";
import downArrow from "../../../assets/images/icons/angle-down.svg";
import mobilePassI from "../../../assets/images/pass/offers-mobile-bg.jpg";
import { smartJoin } from "@lib/helpers";
import { InKindNetworkError } from "../../helpers/InKindNetworkError";
import useIsMobile from "../../hooks/useIsMobile";
import WelcomeBack from "./WelcomeBack";
import ReturnUserNoLongerAvailable from "./ReturnUserNoLongerAvailable";
import NewUserNoLongerAvailable from "./NewUserNoLongerAvailable";
import withEventsLogger from "../events/withEventsLogger";
import useDeviceId from "../events/useDeviceId";
import { useInView } from "react-intersection-observer";
import NoAccount from "./NoAccount";
import useSessionId from "../events/useSessionId";
import { isEmpty } from "lodash-es";

const App = ({
  offer,
  claim,
  cloudinaryPath,
  smsNumber,
  enterprise,
  ikstate,
  locationCount,
  logEvents,
}) => {
  const inkindLogo = require(
    `../../../assets/images/enterprise/${enterprise}/icons/ocp-logo.png`,
  );
  const offerBg = require(
    `../../../assets/images/enterprise/${enterprise}/icons/offerbg-min.png`,
  );
  const offerCodeBg = require(
    `../../../assets/images/enterprise/${enterprise}/icons/offercodeBG.jpg`,
  );
  let claimUse = claim;
  const decodedHash = String(
    !!window.location.hash && window.location.hash.substring(1).length > 0
      ? atob(window.location.hash.split("?")[0].substring(1))
      : "",
  ).toUpperCase();
  const hasHashedClaimCode =
    !claimUse &&
    decodedHash.length > 0 &&
    /^(([a-z0-9\.!#$%&'*+/=?^_`{|}~-]+@[a-z-]+(\.[a-z]{2,})+)|(\+?[a-z0-9]+))$/i.test(
      decodedHash,
    );
  if (hasHashedClaimCode) {
    claimUse = decodedHash;
  }

  const [loading, setLoading] = React.useState(true);
  const [offerCode, setOfferCode] = React.useState(
    !!offer ? String(offer).replace(/[\W_]+/g, "") : offer,
  );
  const [claimCode, setClaimCode] = React.useState(
    !!claimUse && !hasHashedClaimCode
      ? String(claimUse)
          .replace(/[\W_]+/g, "")
          .toUpperCase()
      : claimUse,
  );

  const [inkindStateName, setInkindStateName] = React.useState(
    ikstate?.enterprise?.name,
  );
  const [inkindStateDomain, setInkindStateDomain] = React.useState(
    "https://app." + ikstate?.enterprise?.domain,
  );
  const [inkindStatePrivacyUrl, setInkindStatePrivacyUrl] = React.useState(
    ikstate?.enterprise?.privacy_url,
  );
  const [inkindStateTermsUrl, setInkindStateTermsUrl] = React.useState(
    ikstate?.enterprise?.terms_url,
  );
  const [inkindStateSupportUrl, setInkindStateSupportUrl] = React.useState(
    ikstate?.enterprise?.support_url,
  );
  const [inkindStateAppStoreUrl, setInkindStateAppStoreUrl] = React.useState(
    ikstate?.enterprise?.app_store_url,
  );
  const [inkindStatePlayStoreUrl, setInkindStatePlayStoreUrl] = React.useState(
    ikstate?.enterprise?.play_store_url,
  );
  const [inkindStateRequiredEmails, setInkindStateRequiredEmails] =
    React.useState(ikstate?.enterprise?.required_email_ending);

  const [inkindStateRequiredEmailsDisplay, setInkindStateRequiredEmailDisplay] =
    React.useState(
      ikstate?.enterprise?.required_email_display?.length > 0
        ? `Sorry, a valid ${smartJoin(
            ikstate.enterprise.required_email_display,
            "or",
          )} email address is required to continue.`
        : "Please enter a valid email address.",
    );

  const [slug, setSlug] = React.useState(null);
  const [offerId, setOfferId] = React.useState(null);
  const [brandId, setBrandId] = React.useState(null);
  const [brand, setBrand] = React.useState("inKind restaurants");
  const [value, setValue] = React.useState("$25.00");
  const [minimumAmount, setMinimumAmount] = React.useState("");
  const [minimumSpend, setMinimumSpend] = React.useState(false);
  const [sendType, setSendType] = React.useState("email");
  const [displayState, setDisplayState] = React.useState("");
  const [errorState, setErrorState] = React.useState(false);
  const [phoneNum, setPhoneNum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [imageState, setImageState] = React.useState("");
  const [offerData, setOfferData] = React.useState("");
  const [ppData, setPpData] = React.useState(false);
  const [groupHero, setGroupHero] = React.useState("#");
  const [groupLogo, setGroupLogo] = React.useState("#");
  const [desktopGroupLogo, setDesktopGroupLogo] = React.useState("");
  const [tiers, setTiers] = React.useState([]);
  const [idValue, setIdValue] = React.useState("");
  const [tokenUse, setTokenUse] = React.useState("");
  const [restrictNew, setRestrictNew] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [redeemableDays, setRedeemableDays] = React.useState("");
  const [limitedHours, setLimitedHours] = React.useState(false);
  const [redeemableShortDays, setRedeemableShortDays] = React.useState("");
  const [restrictionText, setRestrictionText] = React.useState("");
  const [restrictionHighlights, setRestrictionHighlights] = React.useState([]);
  const [nextEligibleDate, setNextEligibleDate] = React.useState("");
  const [isTracking, setIsTracking] = React.useState(false);
  const [expiration, setExpiration] = React.useState({
    mode: "days",
    days: 30,
  });
  const [videoUrl, setVideoUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [codeSubmitting, setCodeSubmitting] = React.useState(false);
  const [showError, handleShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [smsOptIn, setSmsOptIn] = React.useState(false);
  const [userPassword, setUserPassword] = React.useState("");
  const [mapSet, setMapSet] = React.useState(false);
  const [offerType, setOfferType] = React.useState("");
  const [offerStatus, setOfferStatus] = React.useState("");
  const [offerGeo, setOfferGeo] = React.useState("");
  const [daysToRedeem, setDaysToRedeem] = React.useState(null);
  const isMobile = useIsMobile();

  let ikname = inkindStateName.replace(" Staging", "");

  const sessionId = useSessionId();

  const body = document
    .getElementsByTagName("body")[0]
    .classList.add("offers-body");

  React.useEffect(() => {
    handleInitDisplay();
  }, []);
  const deviceId = useDeviceId();
  const handleAmplitude = async (eventName, eventProperties) => {
    await logEvents([
      {
        session_id: sessionId,
        event_name: eventName,
        device_id: deviceId,
        event_properties: {
          offer_code: offerCode,
          offer_amount: value.replace("$", ""),
          min_spend_amt: minimumAmount.replace("$", ""),
          min_spend_required: minimumSpend,
          days_to_redeem: daysToRedeem,
          new_user_offer: restrictNew,
          offer_type: offerType,
          variable_day_offer: limitedHours,
          offer_geography: offerGeo,
          offer_status: offerStatus,
          brand_id: brandId,
          ...eventProperties,
        },
      },
    ]);
  };

  React.useEffect(() => {
    if (!deviceId || !offerId) return;
    handleAmplitude("visit_offer_page", {
      offer_code: offer,
    });
  }, [deviceId, offerId, brandId]);

  React.useEffect(() => {
    if (title && offerCode && !isTracking && typeof ttq !== "undefined") {
      ttq.track("ViewContent", {
        contents: [
          {
            content_id: offerCode, // string. ID of the product. Example: "1077218".
            content_type: "product", // string. Either product or product_group.
            content_name: title, // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
      setIsTracking(true);
    }
  }, [title, offerCode]);

  React.useEffect(() => {
    if (errorState === true) {
      setLoading(false);
    }
  }, [errorState]);

  React.useEffect(() => {
    if (
      imageState !== 1 &&
      slug !== null &&
      slug !== "inKind restaurants" &&
      mapSet === false
    ) {
      const map = document.getElementsByClassName("inkind-map")[0];
      slug !== "" ? map.setAttribute("data-scope", slug) : "";
      let script = document.createElement("script");
      script.src = "/assets/maps/initialization.js";
      script.type = "text/javascript";
      document.head.appendChild(script);
      setMapSet(true);
    }
  }, [slug]);

  const handlePost = (path, func, head, body) => {
    setLoading(true);
    let finalHead = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    if (!isEmpty(head)) {
      finalHead = {
        ...head,
        "X-InKind-Session": sessionId,
        "X-Inkind-Device": deviceId,
      };
    }
    let config = { method: "POST" };
    // if (!!head) {
    config.headers = finalHead;
    // }
    if (!!body) {
      config.body = body;
    }
    fetch(path, config)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        func(data);
      })
      .catch((err) => {
        setErrorState(true);
      });
  };

  const handleGet = (path, func) => {
    let config = { method: "GET" };
    config.headers = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    setLoading(true);
    fetch(path, config)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((response) => {
        func(response);
      })
      .catch((err) => {
        setErrorState(true);
        setErrorMessage(err.message);
      });
  };

  const handleFinal = (data) => {
    setLoading(false);
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    const isExistingUser = data.is_existing_user;
    let offerState = OfferState.newUserNoLongerAvailable;
    //No account
    if (!isExistingUser && err) {
      if (err.message === "Offer is not valid.") {
        offerState = OfferState.newUserNoLongerAvailable;
      } else if (
        err.message ===
        `You've already claimed this offer. Install the ${ikname} App to use it if you haven't already.`
      ) {
        offerState = OfferState.prevClaimed;
      } else if (
        err.message ===
        `Your unique offer code has already been claimed. If you are the one who claimed it, install the ${ikname} App to use it if you haven't already.`
      ) {
        offerState = OfferState.prevClaimed;
      }
      setDisplayState(offerState);
      return;
    }
    if (!isExistingUser && !err) {
      offerState = OfferState.noAccount;
      setDisplayState(offerState);
      return;
    }
    //Return user
    if (data.claimed && !err) {
      if (window._ik_on_offer_lead != null) {
        window._ik_on_offer_lead(
          "offer_claim",
          offerCode,
          numeral(value).value(),
        );
      }
      offerState = OfferState.success;
    } else if (data.claimed === true && data.is_existing_user === false) {
      offerState = OfferState.returnUserNewUserOnly;
    } else if (data.is_existing_user && data.is_existing_user === true) {
      offerState = OfferState.authentication;
      if (restrictNew === true && data.is_existing_user === true && err) {
        setErrorState(true);
        setErrorMessage(
          "This offer is only available to new members. But don't worry! You can still get a bonus when you add funds to your existing account:",
        );
      }
    }

    if (
      err &&
      err.message ===
        `You've already claimed this offer. Install the ${ikname} App to use it if you haven't already.`
    )
      offerState = OfferState.prevClaimed;
    if (
      err &&
      err.message === "Sorry, this offer is limited to new signups only."
    )
      offerState = OfferState.returnUserNewUserOnly;
    if (
      err &&
      err.message ===
        `Your unique offer code has already been claimed. If you are the one who claimed it, install the ${ikname} App to use it if you haven't already.`
    ) {
      offerState = OfferState.success;
      setErrorState(true);
    }
    if (err && err.message === "You cannot claim your own referral.")
      offerState = OfferState.claimOwnReferral;
    if (err && err.message === "Offer is not valid.")
      offerState = OfferState.returnUserNoLongerAvailable;
    if (err && err.message === "Invalid email or password.") {
      offerState = OfferState.authentication;
    }
    if (data.used && !data.claimed) offerState = OfferState.prevUsed;
    setDisplayState(offerState);
  };

  const handleClaimData = (data) => {
    let offerState = OfferState.emailInput;
    if (!!data.verified) {
      offerState;
    } else {
      setErrorState(true);
      offerState = OfferState.success;
    }
    setDisplayState(offerState);
    setLoading(false);
  };

  const handleOffer = (response) => {
    const err = !!response?.error ? InKindNetworkError(response.error) : null;
    let offerState = OfferState.newUserNoLongerAvailable;
    setLoading(false);
    if (!!err && !!response.tiers) {
      setTiers(response.tiers);
      setImageState(2);
      setErrorMessage(err.message);
      setErrorState(true);
      setDisplayState(offerState);
      return;
    } else if (!!err) {
      setShareUrl(response?.group?.share_url);
      setPpData(response?.group?.account_types[0].gcos);
      setImageState(0);
      setErrorMessage(err.message);
      setErrorState(true);
      offerState = OfferState.success;
      setDisplayState(offerState);
      return;
    }
    //set various states and variables based on offer data
    setOfferId(response.offer.id);
    setBrandId(response.offer.brand?.brand_id);
    setValue("$" + response.offer.amount);
    setShareUrl(response?.group?.share_url);
    setMinimumAmount("$" + response.offer.minimum_spend_amount);
    setMinimumSpend(response.offer.minimum_spend_required);
    setOfferType(response.offer.type);
    setIdValue(response.offer.id);
    setTitle(response.offer.name);
    setOfferStatus(response?.offer?.status);
    setOfferGeo(response?.offer?.geo_name);
    setDescription(
      response.offer.type === "ambassador_referral" ||
        response.offer.type === "user_referral" ||
        response.offer.is_active_in_campaign
        ? ""
        : response.offer.details,
    );
    setSendType(response.offer.require_phone_to_claim ? "phone" : "email");
    setExpiration(response.offer.expiration);
    setRestrictNew(response.offer.restrict_to_new_users);
    setRedeemableDays(response?.offer?.redeemable_days);
    setLimitedHours(response?.offer?.has_limited_hours);
    setNextEligibleDate(response?.offer?.next_eligible_date);
    setRedeemableShortDays(response?.offer?.redeemable_days_short);
    setRestrictionText(response?.offer?.restriction_text);
    setRestrictionHighlights(response?.offer?.restriction_highligts);
    setDaysToRedeem(
      response?.offer?.expiration?.days
        ? response?.offer?.expiration?.days
        : null,
    );
    setVideoUrl("https://app.inkind.com/videos/inKind.com_Header.mp4");
    if (response.offer?.group) {
      //handle brand images and pp data
      setSlug(response.offer.brand_slugs.join(","));

      if (response?.offer?.brand) {
        setBrand(response.offer.brand.name);
        response.offer.brand.branding?.hero_image?.value
          ? setGroupHero(
              cloudinaryPath +
                "c_fit,w_1920/" +
                response.offer.brand.branding.hero_image.value.replace(
                  /.png/i,
                  ".jpg",
                ),
            )
          : null;
        response.offer.brand.branding?.logo_wide?.dark_mode
          ? setGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.brand.branding.logo_wide.dark_mode,
            )
          : null;
        response.offer.brand.branding?.logo.dark_mode
          ? setDesktopGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.brand.branding.logo.dark_mode,
            )
          : null;
      } else {
        setBrand(response.offer.group.name);
        response.offer.group.branding?.hero_image?.value
          ? setGroupHero(
              cloudinaryPath +
                "c_fit,w_1920/" +
                response.offer.group.branding.hero_image.value.replace(
                  /.png/i,
                  ".jpg",
                ),
            )
          : null;
        response.offer.group.branding?.logo_wide?.dark_mode
          ? setGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.group.branding.logo_wide.dark_mode,
            )
          : null;
        response.offer.group.branding?.logo?.dark_mode
          ? setDesktopGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.group.branding.logo.dark_mode,
            )
          : null;
      }
      setImageState(1);
      setPpData(response?.offer?.group?.account_types[0]?.gcos);
    } else {
      setSlug("inKind restaurants");
      setTiers(response?.tiers ?? null);
      setImageState(2);
    }

    if (!response.offer.additional_code_required) {
      setDisplayState(OfferState.emailInput);
      setLoading(false);
    } else if (!claimCode || claimCode.length === 0) {
      //if there needs to be one, and it needs to be entered manually
      //setSlug("");
      setDisplayState(OfferState.claimCode);
      setLoading(false);
    } else {
      //if there's one in the URL that just needs to be checked
      handlePost(
        "/api/v5/offers/verify_claim_code?claim_code=" +
          encodeURIComponent(claimCode) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleClaimData,
      );
    }
  };

  const handleInitDisplay = () => {
    if (!offerCode || offerCode.length < 1) {
      setDisplayState(OfferState.offerCode);
      setImageState(0);
      setLoading(false);
    } else {
      handleGet("/api/v5/offers/" + offerCode, handleOffer);
    }
  };

  const handleCodeSubmit = (code) => {
    let emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const stripped_code = code.match(emailReg)
      ? code.toUpperCase()
      : String(code).replace(/[\W_]+/g, "");
    if (displayState === OfferState.offerCode) {
      setOfferCode(stripped_code);
      handleGet("/api/v5/offers/" + stripped_code, handleOffer);
    } else if (displayState === OfferState.claimCode) {
      setClaimCode(hasHashedClaimCode ? code : stripped_code);
      handlePost(
        "/api/v5/offers/verify_claim_code?claim_code=" +
          encodeURIComponent(hasHashedClaimCode ? code : stripped_code) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleClaimData,
      );
    }
  };

  const handleVerif = (data) => {
    setDisplayState(OfferState.smsVerification);
    setLoading(false);
    setTokenUse(data.token);
  };

  const verifySmsCode = (verification_code) => {
    if (codeSubmitting) return;

    setCodeSubmitting(true);
    handleShowError(false);
    handlePost(
      "/api/v5/offers/verify_sms_code?token=" +
        encodeURIComponent(tokenUse) +
        "&sms_verification_code=" +
        encodeURIComponent(verification_code) +
        "&id=" +
        encodeURIComponent(offerCode),
      handleSmsResponse,
    );
  };

  const checkVerificationStatus = async () => {
    const headers = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    await fetch(
      "/api/v5/offers/check_if_verified?token=" +
        encodeURIComponent(tokenUse) +
        "&id=" +
        encodeURIComponent(offerCode),
      { method: "POST", headers: headers },
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.token != null) {
          claimOffer(r);
        }
      });
  };

  const handleSmsResponse = (data) => {
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    setLoading(false);
    setCodeSubmitting(false);
    if (!data?.token) {
      handleShowError(true);
      const inputs = document.querySelectorAll("#otp > *");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = "";
      }
      inputs[0].focus();
    } else if (
      data.is_user === false ||
      (data.is_user === true && data.user?.token)
    ) {
      claimOffer(data);
    } else if (data.is_user === true) {
      setDisplayState(OfferState.authentication);
      if (restrictNew === true && err) {
        setErrorState(true);
        setErrorMessage(
          "This offer is only available to new members. But don't worry! You can still get a bonus when you add funds to your existing account:",
        );
      }
    } else if (err) {
      setErrorState(true);
      setErrorMessage(err.message);
    }
  };

  const claimOffer = (data) => {
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    if (data?.error === "Invalid email or password") {
      handleShowError(true);
      setErrorMessage(data.error);
    }
    if (data?.errors && data?.errors[0].message) {
      handleShowError(true);
      setErrorMessage(data?.errors[0].message);
    }
    if (!data) {
      handleShowError(true);
      setLoading(false);
      document.querySelector("input[name='Password']").value = "";
      return;
    }
    if (!!data?.token) {
      setTokenUse(data.token);
    }
    const token = data?.token?.length > 0 ? data.token : tokenUse;
    let url = !!claimCode
      ? "/api/v5/offers/claim_offer?token=" +
        encodeURIComponent(token) +
        "&id=" +
        encodeURIComponent(offerCode) +
        "&claim_code=" +
        encodeURIComponent(claimCode) +
        "&sms_opt_in=" +
        smsOptIn
      : "/api/v5/offers/claim_offer?token=" +
        encodeURIComponent(token) +
        "&id=" +
        encodeURIComponent(offerCode) +
        "&sms_opt_in=" +
        smsOptIn;
    if (!!data?.user?.token) {
      let thisSmsOptIn = {
        sms_opt_in: smsOptIn,
      };
      let bodyUse = JSON.stringify(thisSmsOptIn);
      handlePost(url, handleFinal, {
        Authorization: "Bearer " + data.user.token,
      });
    } else {
      handlePost(url, handleFinal);
    }
  };

  const scrollTo = (where) => {
    document.querySelector(where).scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    if (sendType === "phone") {
      let num = e.target[0]?.value || phoneNum;
      if (title && offerCode && typeof ttq !== "undefined") {
        ttq.track("ClickButton", {
          contents: [
            {
              content_id: offerCode, // string. ID of the product. Example: "1077218".
              content_type: "product", // string. Either product or product_group.
              content_name: title, // string. The name of the page or product. Example: "shirt".
            },
          ],
        });
      }
      handleAmplitude("submit_phone_number_for_verification", {
        phone_number: num,
        offer_code: offer,
      });
      handlePost(
        "/api/v5/offers/send_sms_verification_code?phone_number=" +
          encodeURIComponent(num) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleVerif,
      );
      if (num !== phoneNum) {
        setPhoneNum(num);
      }
    } else if (sendType === "email") {
      handleAmplitude("enter_login_email", { email: e.target[0].value });
      handlePost(
        "/api/v5/offers/validate_email?email=" +
          encodeURIComponent(e.target[0].value.toLowerCase().trim()) +
          "&id=" +
          encodeURIComponent(offerCode),
        claimOffer,
      );
      setEmail(e.target[0].value.toLowerCase().trim());
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    handleAmplitude("enter_login_password", {
      email: e.target[0].value.toLowerCase().trim(),
    });
    handleShowError(false);
    let thisUser = {
      user: {
        email: e.target[0].value.toLowerCase().trim(),
        password: e.target[1].value,
      },
    };
    let bodyUse = JSON.stringify(thisUser);
    // let headersUse = new Headers({ "content-type": "application/json" });
    handlePost(
      "/api/v5/login",
      claimOffer,
      { "content-type": "application/json" },
      bodyUse,
    );
  };
  return (
    <div
      className={`offers-main-container ${
        imageState === 2 ? "offers-pass-container" : ""
      }`}
    >
      {/* <div
        className="loading-container"
        style={{ display: loading === true ? "" : "none" }}
      >
        <div className="loading-screen">
          <div className="loading-ic"></div>
        </div>
      </div> */}
      <div className="offers-main-content">
        <main className="offers-main">
          {imageState !== 1 && ikname === "inKind" ? (
            <div
              className="offers-brand-main-around"
              style={{
                backgroundImage: `url(${
                  imageState === 0
                    ? offerCodeBg
                    : imageState === 2
                      ? isMobile
                        ? ""
                        : offerBg
                      : ""
                })`,
              }}
            >
              <video
                id="video"
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                controls={false}
                controlsList="nofullscreen nodownload noremoteplayback"
                src={videoUrl}
                type="video/mp4"
                className="bg-video"
              />
              <div
                className="header-brand-img-container"
                style={{ flexDirection: imageState === 2 ? "column" : "row" }}
              >
                {imageState === 1 ? (
                  <img src={isMobile ? groupLogo : desktopGroupLogo} />
                ) : null}
                {imageState === 0 ? (
                  <img src={inkindLogo} className="header-ik-logo" />
                ) : (
                  <></>
                )}
                {imageState === 2 ? (
                  <>
                    <img
                      src={inkindLogo}
                      className="header-ik-logo pass-logo"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* <div
              className="header-img-container"
              style={{ flexDirection: imageState === 2 ? "column" : "row" }}
            >
              <img src={isMobile ? groupLogo : desktopGroupLogo} />
            </div> */}
            </div>
          ) : null}
          {imageState === 1 || (imageState !== 1 && ikname === "noshd") ? (
            <div
              className="offers-main-around"
              style={{
                backgroundImage: `url(${
                  imageState === 0
                    ? offerCodeBg
                    : imageState === 2
                      ? isMobile
                        ? mobilePassI
                        : offerBg
                      : groupHero
                })`,
              }}
            >
              <div
                className="header-img-container"
                style={{ flexDirection: imageState === 2 ? "column" : "row" }}
              >
                {imageState === 1 ? (
                  <img src={isMobile ? groupLogo : desktopGroupLogo} />
                ) : (
                  <></>
                )}
                {imageState === 0 ? (
                  <img
                    src={inkindLogo}
                    className="header-ik-logo"
                    alt="Inkind Logo"
                  />
                ) : (
                  <></>
                )}
                {imageState === 2 ? (
                  <>
                    <img
                      src={inkindLogo}
                      className="header-ik-logo pass-logo"
                      alt="Inkind Logo"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="powered-by">
                Courtesy of <img src={ikLogo} />
              </div>
            </div>
          ) : null}
          <div className="main-container">
            <div
              className="grid-container"
              style={{
                marginTop: errorState === true && "0px",
                marginBottom: errorState === true && "0px",
              }}
            >
              <div
                className={
                  displayState === OfferState.altVerification &&
                  errorState === false
                    ? "hide-if-on-mobile"
                    : errorState === true
                      ? "hide-if-error"
                      : displayState === 4
                        ? "welcome-container-center"
                        : displayState >= 1 && displayState < OfferState.success
                          ? "welcome-container"
                          : ""
                }
              >
                {!errorState &&
                  (displayState === OfferState.emailInput ||
                    displayState === OfferState.smsVerification) && (
                    <WelcomeBonus
                      title={title}
                      value={value}
                      displayState={displayState}
                      description={description}
                      restrictionText={restrictionText}
                      restrictionHighlights={restrictionHighlights}
                    />
                  )}
              </div>

              {!errorState && displayState === OfferState.emailInput && (
                <ClaimBox
                  value={value}
                  email={email}
                  phoneNum={phoneNum}
                  sendType={sendType}
                  handleContactSubmit={handleContactSubmit}
                  stateToSet={sendType === "email" ? setEmail : setPhoneNum}
                  restrictNew={restrictNew}
                  setSmsOptIn={setSmsOptIn}
                  expiration={expiration}
                  redeemableShortDays={redeemableShortDays}
                  limitedHours={limitedHours}
                  inkindStateRequiredEmails={inkindStateRequiredEmails}
                  inkindStateRequiredEmailsDisplay={
                    inkindStateRequiredEmailsDisplay
                  }
                  loading={loading}
                  inkindStatePrivacyUrl={inkindStatePrivacyUrl}
                  inkindStateTermsUrl={inkindStateTermsUrl}
                  ikname={ikname}
                  handleAmplitude={handleAmplitude}
                  offerType={offerType}
                />
              )}

              {!errorState && displayState === OfferState.smsVerification && (
                <VerificationBox
                  value={value}
                  phoneNum={phoneNum}
                  setDisplayState={setDisplayState}
                  setSendType={setSendType}
                  verifySmsCode={verifySmsCode}
                  codeSubmitting={codeSubmitting}
                  handleContactSubmit={handleContactSubmit}
                  showError={showError}
                  handleShowError={handleShowError}
                  isPass={imageState === 2 ? true : false}
                  loading={loading}
                  handleAmplitude={handleAmplitude}
                />
              )}

              {!errorState && displayState === OfferState.altVerification && (
                <AltVerification
                  checkVerificationStatus={checkVerificationStatus}
                  smsNumber={smsNumber}
                  goBack={() => setDisplayState(OfferState.smsVerification)}
                />
              )}

              {(errorState || !errorState) &&
                (displayState === OfferState.success ||
                  displayState === OfferState.prevClaimed ||
                  displayState === OfferState.prevUsed) && (
                  <WelcomeBack
                    value={value}
                    displayState={displayState}
                    expirationDays={expiration.days}
                    ikname={ikname}
                    errorState={errorState}
                    inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                    inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                    inkindStateDomain={inkindStateDomain}
                    handleAmplitude={handleAmplitude}
                  />
                )}
              {errorState &&
                (displayState === OfferState.returnUserNoLongerAvailable ||
                  displayState === OfferState.returnUserNewUserOnly) && (
                  <ReturnUserNoLongerAvailable
                    expirationDays={expiration.days}
                    isNewUserOnly={
                      displayState === OfferState.returnUserNewUserOnly
                    }
                    tiers={tiers}
                    ikname={ikname}
                  />
                )}
              {(errorState || !errorState) &&
                (displayState === OfferState.returnUserNoLongerAvailable ||
                  displayState === OfferState.newUserNoLongerAvailable) && (
                  <NewUserNoLongerAvailable
                    ikname={ikname}
                    enterprise={enterprise}
                    inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                    inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                    handleAmplitude={handleAmplitude}
                  />
                )}
              {!errorState && displayState === OfferState.noAccount && (
                <NoAccount
                  value={value}
                  enterprise={enterprise}
                  ikname={ikname}
                  inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                  inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                  handleAmplitude={handleAmplitude}
                  inkindStateDomain={inkindStateDomain}
                />
              )}
              {!errorState && displayState === OfferState.claimOwnReferral && (
                <WelcomeBack
                  value={value}
                  displayState={displayState}
                  expirationDays={expiration.days}
                  ikname={ikname}
                  errorState={errorState}
                  inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                  inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                  inkindStateDomain={inkindStateDomain}
                  handleAmplitude={handleAmplitude}
                />
              )}

              {!errorState && displayState === OfferState.authentication && (
                <SignIn
                  email={email}
                  userPassword={userPassword}
                  setUserPassword={setUserPassword}
                  handleLogin={handleLogin}
                  sendType={sendType}
                  showError={showError}
                  errorMessage={errorMessage}
                  expirationDays={expiration.days}
                  handleShowError={handleShowError}
                  ikname={ikname}
                  inkindStateRequiredEmails={inkindStateRequiredEmails}
                  inkindStateRequiredEmailsDisplay={
                    inkindStateRequiredEmailsDisplay
                  }
                  loading={loading}
                  handleAmplitude={handleAmplitude}
                />
              )}

              {!errorState && displayState === OfferState.offerCode && (
                <OfferCode
                  title={"Enter claim code"}
                  handleCodeSubmit={handleCodeSubmit}
                  placeholder={"Claim code"}
                  loading={loading}
                />
              )}

              {!errorState && displayState === OfferState.claimCode && (
                <OfferCode
                  title={"Enter your offer code"}
                  handleCodeSubmit={handleCodeSubmit}
                  placeholder={"Code"}
                  loading={loading}
                />
              )}
            </div>
            <div
              className="map-show-container"
              style={{
                display:
                  !!slug && !isMobile && imageState !== 1 ? "flex" : "none",
              }}
            >
              <a
                className="map-show"
                onClick={(e) => {
                  scrollTo("#offers-map-section");
                  handleAmplitude("click_explore_map", {
                    phone_number: phoneNum,
                  });
                }}
              >
                Explore locations
                <div
                  style={{
                    width: "100%",
                    height: "23px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <img
                    src={downArrow}
                    alt="Scroll down"
                    className="down-arrow-offers bounce"
                  />
                </div>
              </a>
            </div>
          </div>
        </main>
      </div>

      {!!slug && slug !== "inKind restaurants" && imageState !== 1 && (
        <div className="map-box map-box-dark" id="offers-map-section">
          <div
            className="inkind-map"
            data-headline-class="inkind-map-header"
            data-color="dark"
            data-list-scroll="false"
            data-tiles="true"
            data-max-height="600px"
            data-click-mode="modal"
            data-env={window.location.origin}
            data-offers="true"
          ></div>
        </div>
      )}
      {!!slug && slug === "inKind restaurants" && imageState !== 1 && (
        <div className="map-box map-box-dark" id="offers-map-section">
          <div className="pass-map-header">
            {locationCount}+ Restaurants, Bars & Cafés
          </div>
          <iframe
            src="/embed/map?pass=true&colorMode=black"
            className="inkind-map"
            style={{
              width: "100%",
              height: "720px",
              maxHeight: "90vh",
              margin: "0 auto",
              display: "block",
            }}
          />
        </div>
      )}
    </div>
  );
};

export const OfferState = {
  offerCode: 0,
  claimCode: 1,
  emailInput: 2,

  smsVerification: 3,
  altVerification: 6,

  authentication: 4,
  success: 5,
  noAccount: 7,
  prevClaimed: 8,
  prevUsed: 9,
  returnUserNoLongerAvailable: 10,
  returnUserNewUserOnly: 11,
  newUserNoLongerAvailable: 12,
  claimOwnReferral: 13,
};

export default withEventsLogger(App);
